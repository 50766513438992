@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(./fonts/Roboto-Medium.ttf);
}

/* Ensures 500 is the thickest possible font weight */
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(./fonts/Roboto-Medium.ttf);
}

:root {
  /* Main colors from TOMRA color palette */
  --colors-primary: rgb(31, 192, 218);
  --colors-primary-light: rgb(154, 225, 236);
  --colors-secondary: rgb(110, 206, 178);
  --colors-secondary-light: rgb(206, 233, 223);
  --colors-tertiary: rgb(248, 163, 188);
  --colors-tertiary-light: rgb(252, 225, 232);
  --colors-button: rgb(110, 206, 178);
  --colors-red: rgb(220, 53, 69);
  --colors-yellow: rgb(255, 193, 7);
  --colors-green: rgb(40, 167, 69);
  --colors-grey: rgb(200, 200, 200);
  --colors-background: rgb(244, 248, 249);

  --radius-sm: 10px;
  --radius-lg: 50px;

  --shadow-sm: 0px 1px 2px rgba(0, 0, 0, 0.16);
  --shadow-lg: rgba(0, 0, 0, 0.156863) 0px 3px 10px, rgba(0, 0, 0, 0.227451) 0px 3px 10px;

  --spacing-xs: 0.27rem;
  --spacing-sm: 0.53rem;
  --spacing-md: 1.06rem;
  --spacing-lg: 1.6rem;
  --spacing-xl: 2.4rem;

  --font-size-sm: 0.8rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.2rem;
  --font-size-xl: 2rem;

  --max-page-width: 28rem;
}

/* apply a natural box layout model to all elements */
html {
  box-sizing: border-box;
  touch-action: manipulation;
  font-size: 15px;
}

/* Remove default margin, padding and color from all elements */
/* so we have a completely clean slate */
*,
*:before,
*:after {
  box-sizing: inherit;
  vertical-align: middle;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  background-color: var(--colors-background);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h1 {
  font-size: var(--font-size-xl);
}

h2,
h3,
h4,
h5 {
  font-size: var(--font-size-lg);
}

small {
  font-size: var(--font-size-sm);
}

a {
  text-decoration: none;
}

input,
button {
  color: black;
}

.animated.fast {
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
}

.animated.medium {
  -webkit-animation-duration: 400ms;
  animation-duration: 400ms;
}

.animated.slow {
  -webkit-animation-duration: 600ms;
  animation-duration: 600ms;
}

.centerFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerMargin {
  display: block;
  margin: 0 auto;
}

.noscroll {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.truncateText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

/************* Google Maps *************/

/* Make terms of service link non clickable */
/* Could hide instead, but that would probably make Google sad */
.gmnoprint {
  pointer-events: none;
}

/* Hide "View on Google Maps" link when clicking on a generic item in locations" */
div.view-link {
  display: none;
}

/**************** Animations *********************/

.fade-appear {
  opacity: 0;
}

.fade-appear-done {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-done {
  opacity: 1;
  transition: opacity 200ms;
}

.fade-exit {
  opacity: 0;
  transition: opacity 200ms;
}

.slide-in-bottom-appear {
  transform: translateY(100%);
}

.slide-in-bottom-appear-done {
  transform: translateY(0);
  transition: transform 200ms ease-in-out;
}

.slide-in-bottom-enter {
  transform: translateY(100%);
}

.slide-in-bottom-enter-done {
  transform: translateY(0);
  transition: transform 200ms ease-in-out;
}

.slide-in-bottom-exit {
  transform: translateY(100%);
  transition: transform 200ms ease-in-out;
}

.slide-in-left-appear {
  transform: translateX(100%);
}

.slide-in-left-appear-done {
  transform: translateX(0);
  transition: transform 200ms ease-in-out;
}

.slide-in-left-enter {
  transform: translateX(100%);
}

.slide-in-left-enter-done {
  transform: translateX(0);
  transition: transform 200ms ease-in-out;
}

.slide-in-left-exit {
  transform: translateX(100%);
  transition: transform 200ms ease-in-out;
}

/* For screen readers only */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.side-drawer-container {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 90%;
  background-color: var(--colors-background);
  z-index: 1000;
  overflow-wrap: break-word;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 768px) {
  .side-drawer-container {
    max-width: 50%;
  }
}

@media (min-width: 992px) {
  .side-drawer-container {
    max-width: 40%;
  }
}

@media (min-width: 1200px) {
  .side-drawer-container {
    max-width: 25%;
  }
}

.side-drawer-container > button {
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
  z-index: 99;
}

.bottom-drawer-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 90%;
  padding: var(--spacing-xl);
  background-color: var(--colors-background);
  border-top-right-radius: var(--radius-sm);
  border-top-left-radius: var(--radius-sm);
  z-index: 1000;
  overflow-wrap: break-word;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.bottom-drawer-container > button {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  z-index: 99;
}
